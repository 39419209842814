<template>
  <v-card
    elevation="0"
    style="border: 1px solid #ebebeb"
    class="mx-6 px-7 py-7 rounded-xl"
  >
    <v-row
      class="d-flex justify-space-between rounded-0"
      style="border-bottom: 1px solid #ebebeb"
    >
      <Chat></Chat>

      <v-col cols="6" class="d-flex align-center">
        <span class="wc-h6 text-primary-variant">
          {{ this.mode === "CREATE" ? "Nueva" : "Editar" }} Publicación
        </span>
      </v-col>
      <v-col cols="5">
        <v-row class="d-flex justify-end">
          <v-col cols="4" class="d-flex align-center justify-end">
            <v-btn
              :disabled="disableSaveButton"
              :color="color.secondary"
              elevation="0"
              class="rounded-xl wc text-primary-variant text-capitalize px-7"
              style="font-weight: 700"
              @click="confirmDialog = true"
            >
              Guardar
            </v-btn>
          </v-col>
          <!-- Botón vista previa de la entrada -->
          <v-col cols="4" class="d-flex align-center justify-center">
            <v-btn
              class="rounded-xl"
              elevation="1"
              text
              @click="previewDialog = true"
            >
              <v-icon class="mx-3" :color="color.primaryVariant"
                >mdi-monitor-eye</v-icon
              >
              <span class="text-primary-variant" style="font-weight: 700"
                >Vista previa</span
              >
            </v-btn>
          </v-col>
          <v-col cols="3">
            <span style="font-weight: 700"> Estado </span>
            <div class="d-flex align-center justify-space-between">
              <span
                style="font-size: 11px; font-weight: 500; margin-right: 13px"
              >
                {{ setPublicationStatus ? "Publicado" : "Archivado" }}
              </span>
              <v-switch
                :color="color.primaryVariant"
                dense
                hide-details=""
                class="ma-0"
                v-model="setPublicationStatus"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9" style="border-right: 1px solid #ebebeb">
        <div v-if="setPublicationType === 'BLOG'">
          <blog
            :publicationData="publicationComputed"
            @changeData="handlePost"
          />
        </div>
        <div v-if="setPublicationType === 'VIDEO'">
          <Video
            :publicationData="publicationComputed"
            @changeData="handlePost"
          />
        </div>
        <div v-if="setPublicationType === 'LINK'">
          <Link
            :publicationData="publicationComputed"
            @changeData="handlePost"
          />
        </div>
        <div v-if="setPublicationType === 'DOC'">
          <Doc
            :publicationData="publicationComputed"
            @changeData="handlePost"
          />
        </div>
      </v-col>

      <v-col cols="3" class="pa-5">
        <div class="section">
          <span class="wc-5 subtitle">Tipo de Publicación</span>
          <v-select
            filled
            background-color="#f9fafc"
            :items="publicationTypes"
            hide-details
            v-model="publication.type"
          >
          </v-select>
        </div>
        <div class="section">
          <span class="wc-5 subtitle">Etiquetas</span>
          <v-combobox
            hide-selected
            label="Seleccione las etiquetas pertinentes"
            multiple
            background-color="#f9fafc"
            persistent-hint
            hide-details
            small-chips
            filled
            v-model="publication.labels"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Presiona
                    <kbd>enter</kbd> para crear una nueva opción.
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </div>

        <div class="section">
          <span class="wc-5"
            >¿Desea enviar una notificación por email a los miembros de la
            organización?</span
          >
          <v-radio-group v-model="selectedOption" row>
            <v-radio
              label="Sí, deseo enviar notificación vía email a todos los afiliados"
              value="yes"
              @change="onOptionChange"
            ></v-radio>
            <v-radio label="Cancelar" value="no"></v-radio>
          </v-radio-group>
        </div>
        <div v-if="selectedOption === 'yes'">
          <v-combobox
            v-model="selectedSubgroup"
            label="Mandar notificaciones a:"
            :items="subgroups"
            background-color="#f9fafc"
            persistent-hint
            hide-details
            filled
            :multiple="false"
            hide-selected
          >
          </v-combobox>
        </div>

        <PersonalizedDialog
          hidden
          :hideImage="true"
          :dialog="confirmDialog2"
          :dimensions="{ width: 1113 }"
          @save="confirmYes"
          @discard="confirmNo"
        >
          <template #alertName> Confirmación </template>
          <template #alertContent>
            <div class="d-flex justify-center" width="100%">
              ¿Está seguro de que desea enviar la notificación?
            </div>
          </template>
        </PersonalizedDialog>

        <div class="section" hidden>
          <span class="wc-5 subtitle">Publicar el</span>
          <v-radio-group v-model="radioGroup">
            <v-radio
              v-for="(item, i) in radioOptions"
              :key="i"
              :value="i"
              :label="item.text"
            >
            </v-radio>
          </v-radio-group>
        </div>
        <div class="section" hidden>
          <span class="wc-5 subtitle">Visible Para</span>
          <v-select background-color="#f9fafc" filled hide-details="">
          </v-select>
        </div>
        <div class="section" hidden>
          <span class="wc-5 subtitle">Permitir Comentarios</span>
          <div class="d-flex justify-center align-center">
            <span> Se permiten comentarios </span>
            <v-switch
              class="ma-0 ml-4"
              hide-details
              dense
              :color="color.secondary"
            ></v-switch>
          </div>
        </div>
        <div class="section" hidden>
          <span class="wc-5 subtitle">Autor de la publicación</span>
          <v-select background-color="#f9fafc" filled hide-details="">
          </v-select>
        </div>
      </v-col>
    </v-row>
    <!-- Ventana emergente para visualizar la publicación -->
    <PersonalizedDialog
      hidden
      :hideButton="true"
      :dimentions="{
        width: 1113,
      }"
      :dialog="previewDialog"
      @save="closePreview"
      @discard="previewDialog = false"
    >
      <template #alertName> Vista previa </template>

      <template #alertContent>
        <div class="d-flex justify-center" width="100%">
          <communityCard
            :posts="[{ ...publicationComputed }]"
            @changePosts="handleChangePost"
          >
          </communityCard>
        </div>
      </template>
    </PersonalizedDialog>

    <!-- Ventana emergente para guardar cambios -->
    <PersonalizedDialog
      hidden
      :hideImage="true"
      :dialog="confirmDialog"
      @save="savePublication"
      @discard="confirmDialog = false"
    >
      <template #alertName>
        <span style="font-weight: 600; color: black">{{
          `${setPublicationStatus ? "Publicar entrada" : "Importante"}`
        }}</span>
      </template>
      <template #alertContent>
        <p class="title text-center">
          {{
            `${
              setPublicationStatus
                ? "Estas seguro que deseas guardar cambios? "
                : `No has activado el switch de "Publicar", si avanzas, tu publicación se guardará en el apartado de INACTIVAS, puedes ajustarlo en cualquier momento.`
            }`
          }}
        </p>
      </template>
      <template #button>
        {{ `${setPublicationStatus ? "Publicar" : "Guardar"}` }}
      </template>
    </PersonalizedDialog>
  </v-card>
</template>

<script>
import Chat from "@/components/shared/Chat.vue";
import axios from "axios";

import { mapActions, mapMutations, mapState } from "vuex";
import { colors } from "@/constants/colors";
import { types } from "@/constants/publicationTypes.js";
import PersonalizedDialog from "@/components/shared/PersonalizedDialog.vue";
import communityCard from "../../components/memberDashboard/memberCommunity/communityCard.vue";
import Blog from "../../components/dashboard/myCommunity/publicationsViews/Blog.vue";
import Video from "../../components/dashboard/myCommunity/publicationsViews/Video.vue";
import Link from "../../components/dashboard/myCommunity/publicationsViews/Link.vue";
import Doc from "../../components/dashboard/myCommunity/publicationsViews/Doc.vue";

export default {
  components: {
    Blog,
    Video,
    Link,
    Doc,
    PersonalizedDialog,
    communityCard,
    Chat,
  },
  data() {
    return {
      selectedSubgroup: "Enviar a Todos",
      subgroups: ["Enviar a Todos"],
      selectedOption: "no",
      confirmOption: false,
      confirmDialog2: false,

      color: colors,
      publicationTypes: types,
      confirmDialog: false,
      previewDialog: false,
      radioGroup: 1,
      saveAction: false,
      radioOptions: [
        { text: "Automáticamente" },
        { text: "Rango de tiempo" },
        { text: "Establecer fecha y hora" },
      ],
      publication: {
        name: "",
        description: "",
        type: "BLOG",
        labels: [],
        images: [],
        status: "",
        value: "",
        status: "PUBLISHED",
        expand: false,
        hideOver: false,
        url: "",
      },
    };
  },
  async created() {
    await this.fetchSubgroups();
  },
  methods: {
    ...mapActions("publications", [
      "fetchPublicationById",
      "updatePublication",
      "createPublication",
      "deletePublication",
    ]),
    ...mapMutations("notification", ["show", "hide"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    handlePost(item) {
      Object.assign(this.publication, item);
    },
    async fetchSubgroups() {
      try {
        const response = await axios.get("/users/subgroups");

        this.subgroups = ["Enviar a Todos", ...response.data];
      } catch (error) {
        console.error("Error al obtener los subgrupos:", error);
      }
    },
    async fetchEmails(selectedSubgroup) {
      try {
        const response = await axios.get(
          `/users/emails?subgroup=${selectedSubgroup}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching emails:", error);
        this.show({
          color: "error",
          text: "Error al obtener los correos electrónicos.",
        });
        return [];
      }
    },

    onOptionChange() {
      this.selectedOption = "yes";
      if (this.selectedOption === "yes") {
        this.confirmDialog2 = true;
      } else {
        this.selectedSubgroup = "";
      }
    },
    confirmYes() {
      this.confirmDialog2 = false;
      this.selectedOption = "yes";
      this.confirmOption = true;
    },
    confirmNo() {
      if (this.confirmOption == true) {
        this.confirmDialog2 = false;
      } else {
        this.selectedOption = "no";
        this.confirmDialog2 = false;
      }

      console.log("not");
    },

    handleChangePost({ property, value }) {
      this.$set(this.publication, property, value);
    },

    youtubeCut(youtube) {
      let youtubeId = "";

      if (youtube.startsWith("https://youtu.be/")) {
        youtubeId = youtube.split("https://youtu.be/")[1];
      } else {
        if (youtube.includes("?v=")) {
          youtubeId = youtube.split("?v=")[1];
        } else if (youtube.includes("&v=")) {
          youtubeId = youtube.split("&v=")[1];
        }
      }
      youtubeId = youtubeId.split("&")[0];
      return `https://www.youtube.com/embed/${youtubeId}`;
    },

    async savePublication() {
      try {
        this.loading();
        if (this.publication.type === "DOC" && this.publication.value.type) {
          let formData = new FormData();
          formData.append("file", this.publication.value);
          formData.append("publicationId", this.publication._id);
          const fileResponse = await this.axios.put(
            "/publications/upload-image",
            formData
          );
          Object.assign(
            this.publication.images,
            fileResponse.data.publication.images
          );
          this.publication.value =
            fileResponse.data.publication.images.slice(-1)[0];
        }
        delete this.publication.hideOver;
        delete this.publication.expand;
        delete this.publication.url;
        delete this.publication.__v;
        let response;

        if (this.selectedOption === "yes") {
          console.log("entra");
          try {
            console.log("entra2");

            const emails = await this.fetchEmails(this.selectedSubgroup);

            console.log("selected", this.selectedSubgroup);
            await this.axios.post("users/send-publication-notification", {
              publicationTitle: this.publication.name,
              emails: emails,
            });
            console.log("entra4");
          } catch (error) {
            console.log("entra5");

            this.show({
              color: "error",
              text: error.response.data || "Error al enviar la notificación.",
            });
            console.log("entra6");

            return;
          }
        }

        response = await this.updatePublication(this.publication);
        if (response.status) {
          this.saveAction = true;
          this.show({
            time: 3000,
            color: "success",
            text: `Se ha ${
              this.mode === "CREATE" ? "guardado" : "actualizado"
            } los datos de la publicación`,
          });
        }
      } catch (error) {
        this.show({
          time: 3000,
          color: "error",
          text: `Ha habido un error al ${
            this.mode === "CREATE" ? "guardar" : "actualizar"
          } los cambios`,
        });
      } finally {
        this.loaded();
        this.closeWindow();
      }
    },

    closePreview() {
      this.previewDialog = false;
    },

    closeWindow() {
      setTimeout(() => {
        this.$router.push({
          name: "myCommunity",
        });
      }, 2000);
    },

    async deleteOrganizationPublication(item) {
      const { name, description, value, type } = this.publication;
      try {
        if (
          name === "" ||
          description === "" ||
          (type == "BLOG" && value.includes["<p></p>"]) ||
          value == ""
        ) {
          this.loading();
          await this.deletePublication(item);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
        this.closeWindow();
      }
    },
  },
  computed: {
    ...mapState("publications", ["publicationId", "mode"]),
    setPublicationStatus: {
      get() {
        return this.publication.status === "PUBLISHED" ? true : false;
      },
      set(value) {
        value
          ? (this.publication.status = "PUBLISHED")
          : (this.publication.status = "DRAFT");
      },
    },
    setPublicationType() {
      return this.publication.type;
    },

    disableSaveButton() {
      return !(this.publication.name !== "");
    },

    publicationComputed() {
      return this.publication;
    },
  },
  // beforeDestroy() {
  //   if (this.saveAction==false) {
  //       this.deleteOrganizationPublication(this.publication)
  //   }
  // },

  async mounted() {
    console.log(this.publicationId, this.mode);
    try {
      this.loading();
      const postId = this.publicationId;
      if (postId) {
        let response = await this.fetchPublicationById(postId);
        this.publication = Object.assign(this.publication, {
          ...response.data,
          url: "",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loaded();
    }
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-weight: 600;
}

.section {
  margin-bottom: 20px;
}

.section > :nth-child(2) {
  margin-inline: 10px;
}
.drop-zone {
  width: 400px;
  height: 150px;
  border: 2px dashed rgb(149, 227, 253);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
