<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        rounded
        filled
        class="rounded-lg"
        background-color="#f9fafc"
        label="Título de la publicación"
        persistent-hint
        hint="i"
        v-model="publicationDataComputed.name"
      >
        <template #message="{}">
          <span style="font-weight: 500"
            >Escribe el título con el que quieres publicar la entrada</span
          >
        </template>
      </v-text-field>
    </v-col>
    <v-col
      cols="12"
      v-if="publicationDataComputed.url != ''"
      class="d-flex justify-end align-center"
    >
      <v-btn
        class="rounded-xl white--text"
        @click="removeFile"
        elevation="0"
        color="red"
      >
        Eliminar Archivo <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" class="d-flex justify-center">
      <v-card
        elevation="0"
        width="90%"
        @click="$refs.fileInput.click()"
        @dragover.prevent
        @drop.prevent="getFile"
        class="inputCard rounded-md d-flex justify-center align-center"
      >
        <v-sheet
          v-if="publicationDataComputed.url == ''"
          height="210"
          class="d-flex align-center"
        >
          <p class="text-center">
            <span class="wc-subtitle-2 innerText"
              >Arrastre o haga click para cargar</span
            >
            <br />
            <span>Se aceptan archivos: .jpg .png .pdf no mayores a 5 Mb</span>
          </p>
        </v-sheet>
        <!-- <embed
          v-else-if="
            (publicationDataComputed.url !== '' &&
              (publicationDataComputed.url).includes('pdf')) ||
            (publicationDataComputed.value.type).includes('pdf') 
          "
          :src="formatUrl"
          width="100%"
          height="650"
          type="application/pdf"
        />

        <v-img
          v-else-if="
            publicationDataComputed.url !== '' ||
            (publicationDataComputed.url).includes('51') ||            
            (publicationDataComputed.value.type).includes('image')
          "
          max-height="500"
          contain
          :src="publicationDataComputed.url"
          alt="content"
        />  -->
        <embed
          v-if="publicationDataComputed.url.includes('pdf') || (publicationDataComputed.url.includes('blob') && publicationDataComputed.value.type.includes('pdf'))"
          :src="formatUrl"
          width="100%"
          height="650"
          type="application/pdf"
        />

        <v-img
         v-else-if="
            publicationDataComputed.url !== ''
          "
          max-height="500"
          contain
          :src="publicationDataComputed.url"
          alt="content"
        /> 
        <input
          type="file"
          hidden
          :accept="['.jpg', '.png', '.jpeg', '.pdf']"
          ref="fileInput"
          @change="getFile"
        />
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="publicationDataComputed.description"
        rounded
        filled
        hint="i"
        label="Descripción"
        background-color="#f9fafc"
        persistent-hint
      >
        <template #message="{}">
          <span style="font-weight: 500"
            >Ingresa una descripción corta de la publicación de hasta 50
            palabras</span
          >
        </template>
      </v-textarea>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  props: {
    publicationData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    removeFile() {
      this.publicationDataComputed.url = "";
      this.publicationDataComputed.value = null;
      this.$refs.fileInput.value = null;
    },
    getFile(event) {
      let file = null;
      this.publicationDataComputed.url = "";
      if (event.target.files) {
        file = event.target.files[0];
      } else if (event.dataTransfer.files) {
        file = event.dataTransfer.files[0];
      }
      if (file) {
        this.publicationDataComputed.url = URL.createObjectURL(file);
        // this.type = file.type;
        this.publicationDataComputed.value = file;
        console.log(this.publicationDataComputed);
      }
    },
  },
  created() {
    if (this.publicationDataComputed.value !== "") {
      this.publicationDataComputed.url = this.publicationDataComputed.value;
    }
  },
  computed: {
    formatUrl() {
      if ( this.publicationDataComputed.url.includes("blob") ) {
        return this.publicationDataComputed.url;
      }
      return (
        "https://drive.google.com/viewerng/viewer?embedded=true&url=" +
        this.publicationDataComputed.url
      );
    },
    publicationDataComputed: {
      get() {
        return this.publicationData;
      },
      set() {
        this.$emit("changeData", this.publicationData);
      },
    },
  },
};
</script>
<style scoped>
.innerText {
  letter-spacing: 3px;
  font-weight: 800;
}
.inputCard {
  border: 2px solid #b2e5fd;
  border-style: dashed;
}
</style>